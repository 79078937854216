/** @jsx jsx */
import { jsx, Flex, Grid, Box, Text } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import React, { useState, useEffect } from "react"
import NavMenuDesktop from "../components/navMenuDesktop"
import NavMenuMobile from "../components/navMenuMobile"
import Footer from "../components/footer"
import { v4 as uuidv4 } from "uuid"
import { Document, Page } from "react-pdf"
import { FaImdb, FaFileAlt } from "react-icons/fa"
import LogoSpotlight from "../images/logo-spotlight.svg"
import LogoMandy from "../images/logo-mandy-outlines.svg"
import { Link } from "gatsby"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import SEO from "../components/seo"

const CVPage = ({ data }) => {
  const [breakpoint, setBreakpoint] = useState("mobile")
  const handleResize = () => {
    let size = window.innerWidth
    if (size < 640) {
      setBreakpoint("mobile")
    } else if (size < 832) {
      setBreakpoint("tablet")
    } else {
      setBreakpoint("desktop")
    }
    console.log(size)
    window.addEventListener("resize", handleResize)
  }
  useEffect(() => {
    handleResize()

    //return () => window.removeEventListener('resize', handleResize);
  }, [])

  const {
    link_imdb,
    link_spotlight,
    link_vimeo,
    link_mandy,
    link_twitter,
    link_facebook,
  } = data.contact.frontmatter
  return (
    <>
      <SEO title="CV" />
      <NavMenuMobile />
      <Grid
        gap={2}
        columns={["none", "1fr 2fr", "1fr 3fr"]}
        sx={{
          //bg: lighten('primary', 0.35),
          bg: "primaryBG",
          px: 3,
        }}
      >
        <section>
          <NavMenuDesktop navPosition="left" />
        </section>
        <section sx={{ minHeight: "100vh" }}>
          <h1
            sx={{
              color: "primary",
              mt: ["75px", 3],
            }}
          >
            {data.cv.frontmatter.title}
          </h1>
          <div
            dangerouslySetInnerHTML={{ __html: data.cv.html }}
            sx={{
              bg: "white",
              p: 3,
            }}
          />
          <div
            sx={{
              my: 3,
              textAlign: ["center", "left"],
              "& svg": {
                width: "1em",
                fill: "currentcolor",
                stroke: "currentcolor",
                "& path": {
                  fill: "currentcolor",
                },
              },
              "& a": {
                transition: "color 0.3s",
                color: "primary",
                fontSize: 5,
                m: 3,
                "&:hover, &:focus": {
                  color: darken("primary", 0.5),
                },
              },
            }}
          >
            <a href={link_imdb}>
              <FaImdb />
            </a>
            <a href={link_spotlight}>
              <LogoSpotlight />
            </a>
            <a href={link_mandy}>
              <LogoMandy />
            </a>
            <a href={data.cv.frontmatter.printable_cv_url.publicURL}>
              <FaFileAlt />
            </a>
          </div>
          <Flex
            sx={{
              mb: 3,
              display: ["block", "flex"],
            }}
          >
            <Box
              sx={{
                bg: "white",
                p: 3,
                width: ["100%", "35%"],
                mr: ["0", "5%"],
                mb: [3, 0],
                "& h2": {
                  mt: 0,
                },
                "& .stat-title": {
                  color: "primary",
                },
              }}
            >
              <h2>Stats</h2>
              <table>
                <tbody>
                  {data.cv.frontmatter.stat_list.map(stat => {
                    return (
                      <tr key={uuidv4()}>
                        <td className="stat-title">{stat.stat_title}: </td>
                        <td>{stat.stat_text}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Box>
            <Box
              sx={{
                bg: "white",
                p: 3,
                width: ["100%", "65%"],
                "& h2": {
                  mt: 0,
                },
                "& span": {
                  color: "primary",
                },
              }}
            >
              <h2>Skills</h2>
              {data.cv.frontmatter.skills.map(skill => {
                return (
                  <Text key={uuidv4()}>
                    <p>
                      <span>{skill.skill_title}</span>: {skill.skill_text}
                    </p>
                  </Text>
                )
              })}
            </Box>
          </Flex>
          <Box
            sx={{
              //responsive table done by package above
              "& .credit_section": {
                textAlign: "center",
                bg: "white",
                //'border': ['none','2px solid'],
                //'borderColor': 'accent',
                my: 3,
                mx: 0,
              },
              "& h2": {
                color: "primary",
                textAlign: "center",

                zIndex: 3,
              },
              "& .responsiveTable tbody tr": {
                mb: "10px",
                borderColor: "muted",
              },
              "& .responsiveTable tbody td": {
                verticalAlign: "top",
              },
              "& table": {
                width: ["100%", "90%"],
                margin: "0 auto",
                pb: 3,
                "& td": {
                  py: 2,
                },

                "& thead": {
                  display: ["table-cell", "none"],
                },

                "& td.character": {
                  textAlign: "left",
                  width: ["auto", "20%"],
                },
                "& td.show": {
                  textAlign: "left",
                  textTransform: "uppercase",
                  width: ["auto", "30%"],
                },
                "& td.venue": {
                  textAlign: "right",
                  width: ["auto", "30%"],
                },
                "& td.director": {
                  textAlign: "right",
                  width: ["auto", "20%"],
                },
                "& .tdBefore": {
                  textTransform: "none",
                },
                "& .responsiveTable tbody tr": {
                  border: "none",
                  //borderTop: '1px solid',
                },
              },
            }}
          >
            {data.cv.frontmatter.credits.map(credit_section => {
              return (
                <div className="credit_section" key={uuidv4()}>
                  <h2>{credit_section.title}</h2>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Character</Th>
                        <Th>Show</Th>
                        <Th>Company/Venue</Th>
                        <Th>Director/Producer</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {credit_section.credits.map(credit => {
                        return (
                          <Tr key={uuidv4()}>
                            <Td className="character">{credit.character}</Td>
                            <Td className="show">{credit.show}</Td>
                            <Td className="venue">{credit.venue}</Td>
                            <Td className="director">{credit.director}</Td>
                          </Tr>
                        )
                      })}
                    </Tbody>
                  </Table>
                </div>
              )
            })}
          </Box>
          <Box sx={{ mb: 4 }}>
            <Flex
              sx={{
                "& h2": {
                  color: "primary",
                  mr: 4,
                },
                "& svg": {
                  width: "1em",
                  fill: "currentcolor",
                  stroke: "currentcolor",
                  "& path": {
                    fill: "currentcolor",
                  },
                },
                "& a": {
                  transition: "color 0.3s",
                  color: "primary",
                  fontSize: 5,

                  mt: "20px",
                  "&:hover, &:focus": {
                    color: darken("primary", 0.5),
                  },
                },
              }}
            >
              <h2>Printable CV</h2>
              <a href={data.cv.frontmatter.printable_cv_url.publicURL}>
                <FaFileAlt />
              </a>
            </Flex>
            <div
              sx={{
                // width: '100%',
                "& .react-pdf__Page__canvas": {
                  mx: "auto",
                  mb: 2,
                },
              }}
            >
              <Document file={data.cv.frontmatter.printable_cv_url.publicURL}>
                {breakpoint === "mobile" && (
                  <>
                    <Page width="300" pageNumber={1} />
                    <Page width="300" pageNumber={2} />
                    <Page width="300" pageNumber={3} />
                  </>
                )}
                {breakpoint === "tablet" && (
                  <>
                    <Page width="450" pageNumber={1} />
                    <Page width="450" pageNumber={2} />
                    <Page width="450" pageNumber={3} />
                  </>
                )}

                {breakpoint === "desktop" && (
                  <>
                    <Page width="800" pageNumber={1} />
                    <Page width="800" pageNumber={2} />
                    <Page width="800" pageNumber={3} />
                  </>
                )}
              </Document>
            </div>
          </Box>
        </section>
      </Grid>
      <Footer navPosition="left" />
    </>
  )
}

export default CVPage

export const query = graphql`
  query {
    cv: markdownRemark(frontmatter: { templateKey: { eq: "page-cv" } }) {
      id
      frontmatter {
        title
        printable_cv_boolean
        printable_cv_url {
          id
          publicURL
        }
        credits {
          title
          credits {
            character
            director
            show
            venue
          }
        }
        skills {
          skill_title
          skill_text
        }
        stat_list {
          stat_title
          stat_text
        }
      }
      html
    }
    contact: markdownRemark(
      frontmatter: { templateKey: { eq: "page-contact" } }
    ) {
      id
      frontmatter {
        email
        link_spotlight
        link_imdb
        link_facebook
        link_twitter
        link_mandy
        link_vimeo
        cv_pdf {
          id
          publicURL
        }
      }
      html
    }
  }
`
